$(function () {

	$(document).foundation({
	});

	var anchors = ['welcome', 'intro', 'features', 'register', 'about'];

	var toggleMenu = function() {
		$('header.site-menu').toggleClass('active');
		$('.menu-mobile-items').toggleClass('visible');
	}

	$('.overlay .content').slimScroll({
		height: 'auto',
		touchScrollStep: 50
	});

	$(window).resize(function() {
		console.log('resize');
		// Refresh slimscroll height
		$('.overlay .content').slimScroll({
			height: 'auto',
			touchScrollStep: 50
		});
	});

	$('#fullpage').fullpage({
		verticalCentered: false,
		resize: false,
		anchors: anchors,
		menu: 'header.site-menu ul',

		onLeave: function (index, nextindex, direction){
			var href = location.href.split('/');
			var path = href[3].split('#').shift() + anchors[nextindex -1];
			ga('send', 'pageview', path);
		}
	});

	$.fn.fullpage.setAutoScrolling(false);


	$('.overlay').on('click', '.close', function (event) {
		event.preventDefault();
		$('.overlay.active').removeClass('active');
		$('.overlayLink a.active').removeClass('active');
	});


	$('.toggleMenu').on('click', function (event) {
		toggleMenu();
	});

	$('.menu-mobile-items').on('click', 'a', function (event) {
		toggleMenu();
	});

	/**
	 * overlays
	 */
	$('.overlayLink').on('click', 'a', function (event) {
		event.preventDefault();

		var link = $(this);

		if (link.hasClass('active')) {
			link.removeClass('active');
			$('.overlay.active').removeClass('active');
		} else {
			$('.overlay.active').removeClass('active');
			$('.overlayLink a.active').removeClass('active');

			$('.overlayLink').removeClass('active');
			link.addClass('active');

			var overlay = link.attr('data-overlay');
			$('.overlay.' + overlay).addClass('active');
		}
	});

  $(function() {
    $("img.lazy").show().lazyload({
        threshold : 200,
        effect : "fadeIn",
        skip_invisible : true,
        event : "sporty"
    });
  });

  $(window).bind("load", function() {
      var timeout = setTimeout(function() {
          $("img.lazy").trigger("sporty")
      }, 750);
  });

  var welcomeBackgroundImage = $('#welcome-page').css('background-image').replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
  $('<img/>').attr('src', welcomeBackgroundImage).load(function() {
		$(this).remove(); // prevent memory leaks
		$('.loading-placeholder').fadeOut(600, function(){
			$('#fullpage').fadeIn(600);
			$('.site-menu').fadeIn(600);
		})
	});

});
